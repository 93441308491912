import service from "../service.js";

export const serchDeviceAnnex = params => {
    return service({
        method: 'POST',
        url: '/deviceAnnex/serchDeviceAnnex',
        params
    })
}

export const addDeviceAnnex = (data) => {
    return service({
        method: 'POST',
        url: '/deviceAnnex/addDeviceAnnex',
        data
    })
}

export const updateDeviceAnnex = (data) => {
    return service({
        method: 'POST',
        url: '/deviceAnnex/updateDeviceAnnex',
        data
    })
}

export const delDeviceAnnex = (id) => {
    return service({
        method: 'GET',
        url: '/deviceAnnex/delDeviceAnnex',
        params: {
            id
        }
    })
}

export const getDeviceAnnex = (id) => {
    return service({
        method: 'GET',
        url: '/deviceAnnex/getDeviceAnnex',
        params: {
            id
        }
    })
}